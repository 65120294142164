<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container class="pt-0">
      <v-row class="ma-0 pa-0">
        <v-col
          class="pa-0"
          cols="6"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            class="date-picker"
            :return-value.sync="orderDate"
            :width="datePickerWidth"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="orderDate"
                label="생산기준일"
                prepend-icon="mdi-calendar"
                autocomplete="off"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="orderDate"
              locale="ko-ko"
              @input="onDateInput"
            />
          </v-dialog>
        </v-col>
      </v-row>
      <div class="work-order-grid">
        <dx-data-grid
          ref="refWorkOrderGrid"
          :data-source="workOrderInfoList"
          :show-borders="true"
          :show-row-lines="false"
          :show-column-lines="true"
          :hover-state-enabled="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :allow-column-reordering="true"
          :height="gridHeight"
          :row-alternation-enabled="true"
          :focused-row-enabled="true"
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
        >
          <DxColumnFixing :enabled="true" />
          <DxColumn
            :allow-editing="false"
            :fixed="false"
            caption="작업번호"
            data-field="seqId"
            :visible="false"
          />
          <DxColumn
            :allow-editing="true"
            caption="품목유형"
            data-field="품목유형"
            :visible="true"
            sort-order="asc"
            :min-width="80"
          >
            <DxLookup
              :data-source="goodsLookup"
              display-expr="코드명"
              value-expr="코드"
            />
          </DxColumn>
          <DxColumn
            :allow-editing="true"
            :fixed="false"
            caption="품번"
            data-field="품번"
            sort-order="asc"
            :min-width="120"
          />
          <DxColumn
            :allow-editing="true"
            caption="계획"
            data-field="수량"
            :visible="true"
            format="#,##0"
            :min-width="60"
          />
          <DxColumn
            :allow-editing="true"
            caption="시작시간"
            data-field="생산시작시간"
            data-type="datetime"
            format="HH:mm"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="종료시간"
            data-field="생산종료시간"
            data-type="datetime"
            format="HH:mm"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="라인명"
            data-field="라인명"
            :visible="true"
            :min-width="80"
          />
          <DxColumn
            :allow-editing="true"
            caption="품명"
            data-field="품명"
            :visible="true"
            :min-width="120"
          />
          <DxSummary>
            <DxTotalItem
              value-format="#,##0"
              column="품번"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false" />
          <dx-scrolling
            mode="virtual"
          />
          <dx-sorting mode="multiple" />
        </dx-data-grid>
      </div>
      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
// import HttpService from '../../share/service/HttpService'
// import AesCrypto from '../../share/service/AesCrypto'
import DxDataGrid, {
  DxScrolling, DxColumnFixing, DxTotalItem, DxLookup,
  DxSummary, DxColumn, DxPaging, DxSorting
} from 'devextreme-vue/data-grid'

export default {
  name: 'WorkOrder',
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxColumnFixing,
    DxTotalItem,
    DxLookup,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      workOrderInfoList: null,
      gridHeight: null,
      orderDate: AppLib.getToday(),
      goodsLookup: null,
      modal: false,
      datePickerWidth: '350px'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
      return
    }
    this.datePickerWidth = this.getDatePickerWidth()
    this.orderDate = AppLib.getToday()
    this.goodsLookup = this.$store.getters.getBaseDataByType(ConstDef.품목유형)
    this.refreshWorkOrder()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
  },
  methods: {
    handleResize () {
      this.gridHeight = (this.$Q('html').height() - 90) - 30
    },
    getDatePickerWidth () {
      return `${AppLib.getDatePickerWidth(this.$store)}px`
    },
    getPermission () {
      const worker = this.$_.findWhere(ConstDef.USER_PERMISSION, { groupName: '생산현장관리자' }).permission |
        this.$_.findWhere(ConstDef.USER_PERMISSION, { groupName: '생산현장직' }).permission
      return worker & this.userInfo.permission ? this.userInfo.division : 'MANAGER'
    },
    onDateInput (e) {
      this.$refs.dialog.save(this.orderDate)
      this.refreshWorkOrder()
    },
    refreshWorkOrder () {
      this.isLoading = true
      const permission = this.getPermission()
      this.$_sp.runNoEncodeFindProc('spFindAllWorkOrderByDateAndPermission', { 생산기준일: this.orderDate, division: permission })
        .then((data) => {
          this.isLoading = false
          this.workOrderInfoList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
  .dx-toolbar-before {
    width: 300px!important;
  }
</style>
